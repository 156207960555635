import React, { Component } from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import './sliderInput.scss'
const SliderWithTooltip = createSliderWithTooltip(Slider);
let marks = {
  10: '',
  20: '',
  30: '',
  40: '',
  50: '',
  60: '',
  70: '',
  80: '',
  90: '',
}

function percentFormatter(v) {
  return `${v}`;
}

class SliderInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      marks: {}
    };
  }

  componentDidMount() {
    let { max } = this.props
    let marks = {}
    if(max > 0) {
      let i = max/10
      for( i; i < max; i = i + max/10) {
        marks[i] = ''
      }
    }
    this.setState({marks: marks})
  }

  onChangeSlider = (value) => {
    this.setState({value: value})
  }

  onChangeInput = (e) => {
    if(e.target.value.match("^[0-9]*$") != null) {
      let {min, max} = this.props
      let value = e.target.value
      if (value == 0) {
        this.setState({value: value})
        return
      } 
      value = this.props.allowDecimal ? e.target.value : Math.floor(e.target.value)
      if(value > max) {
        value = max
      } else if (value < min) {
        value = min
      }
      this.setState({value: value})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.value != this.state.value) {
      this.props.changeValue(this.state.value, this.props.name)
    }
  }

  render() {
    let {min, max, label} = this.props
    let {value, marks} = this.state
    return (
      <div className="sliderInput">
        <div className="label-range">
          <label>{label} <span style={{color: "#D94D51"}}>*</span></label>
          <input value={this.state.value} inputMode="numeric" onChange={this.onChangeInput} type="number" min={min} max={max}/>
        </div>
        <div className="slider_container">
          <Slider
            min={min}
            max={max}
            value={value}
            // marks={marks}
            onChange={this.onChangeSlider}
          />
        </div>
      </div>
    )
  }
}

export default SliderInput;