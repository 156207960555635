import React, { Component } from 'react'
import SignLayout from '../layouts/SignLayout';

import SEO from '../components/SEO/SEO';
import FounderCalculator from '../containers/FounderCalculator/FounderCalculator'
import CountryContext from '../context/CountryContext'

let title = "Founder's Fund Calculator"
let descriptions = "Founder's Fund Calculator helps you estimate your referral earnings, the more people you refer the more you earn."
// import TopStatic from '';

class Driver extends Component {
    static contextType = CountryContext
    render() {
        const countryCode = this.context && this.context.country
        return (
            <SignLayout>
              <SEO title={title} description={descriptions} />
              <FounderCalculator countryCode={countryCode}/>
            </SignLayout>
        )
    }
}

export default Driver;